<template>
    <b-card class="solicitudviajefase" bg-variant="light">
        <template v-slot:header>
            <b-button-close v-if="fase!='IDA' && fase!='VUELTA' && fase!='PARADA #1'" @click="eliminarparada"></b-button-close>
        </template>

        <b-form-group label-cols-lg="3" :label="fase" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">

            <div v-if="fase!='IDA'" class="custom-control custom-switch text-left">
                <b-form-checkbox v-model="item.autocompletar" name="check-button" switch>
                    {{ textos.autocompletar }}
                </b-form-checkbox>
            </div>

            <b-form-group label-align="left" label-align-md="right" label-cols-md="3" :label="textos.origen.label" class="mb-2">
                <b-form-input required
                              v-model="item.origen"
                              @change="onChange_autocompletar"
                              type="text"
                              :disabled="fase!='IDA' && item.autocompletar"
                              :placeholder="textos.origen.placeholder">
                </b-form-input>
            </b-form-group>
            <b-form-group label-align="left" label-align-md="right" label-cols-md="3" :label="textos.destino.label" label-for="ida_destino" class="mb-2" data-description="Si tu viaje incluye distintas paradas, indica aquí el primer destino de tu viaje">
                <b-form-input required
                              v-model="item.destino"
                              @change="onChange_autocompletar"
                              type="text"
                              :disabled="fase=='VUELTA' && item.autocompletar"
                              :placeholder="textos.destino.placeholder">
                </b-form-input>
            </b-form-group>
            <b-form-group label-align="left" label-align-md="right" label-cols-md="3" :label="textos.mediotransporte.label" class="mb-2">
                <b-form-select required
                               v-model="item.mediodesplazamiento.valor"
                               @change="onChange_autocompletar"
                               style="text-align:left"
                               :disabled="fase=='VUELTA' && item.autocompletar"
                               :options="textos.mediotransporte.options">
                </b-form-select>
            </b-form-group>


            <div v-if="item.mediodesplazamiento.valor=='LAN'" class="form-row mb-2">
                <div class="col-sm-12 text-left col-md-3 text-md-right">
                    <span v-if="fase=='IDA'">{{textos.fechasalida.labellanzaderaida}}</span>
                    <span v-else>{{ textos.fechasalida.labellanzaderavuelta }}</span>
                </div>
                <div class="col-6 col-sm-5 col-md-4 col-lg-3">
                    <b-form-input required
                                  v-model="item.cuando.fecha"
                                  @change="onChange_autocompletar"
                                  :disabled="fase!='IDA' && item.autocompletar"
                                  type="date">
                    </b-form-input>
                </div>
                <div class="col-6 col-sm-4 col-md-3 col-lg-2">
                    <b-form-input required
                                  v-model="item.cuando.hora"
                                  @change="onChange_autocompletar"
                                  type="time">
                    </b-form-input>
                </div>
            </div>
            <div v-else class="form-row mb-2">
                <div class="col-sm-12 text-left col-md-3 text-md-right mt-3">
                    {{ textos.fechasalida.label }}
                </div>
                <div class="col-sm-12 text-left pt-sm-2 col-md-9">
                    <b-form-input required
                                  v-model="item.cuando.fecha"
                                  style="width:200px"
                                  @change="onChange_autocompletar"
                                  :disabled="fase!='IDA' && item.autocompletar"
                                  type="date">
                    </b-form-input>
                </div>
            </div>

            <div v-if="item.mediodesplazamiento.requiereindicarmediocomollegar">

                <b-form-group label-cols-sm="3" label-for="ida_preferenciahoraria" label-align-sm="right" class="mb-0"
                              :label="textos.preferenciahoraria.label">
                    <b-form-radio-group
                            v-model="item.mediodesplazamiento.preferenciahoraria.valor"
                            class="pt-2"
                            style="text-align:left"
                            :options="textos.preferenciahoraria.options"
                    ></b-form-radio-group>
                    <b-form-textarea
                            v-model="item.mediodesplazamiento.preferenciahoraria.observaciones"
                            class="text-align-left"
                            :placeholder="textos.preferenciahoraria.placeholderobservaciones"
                    ></b-form-textarea>
                </b-form-group>
                <b-form-group label-cols-sm="3" label-for="ida_preferenciaposicion" label-align-sm="right" class="mb-0"
                              :label="textos.preferenciaposicion.label">
                    <b-form-radio-group
                            v-model="item.mediodesplazamiento.preferenciaposicion.valor"
                            @input="onChange_autocompletar"
                            class="pt-2"
                            style="text-align:left"
                            :options="textos.preferenciaposicion.options"
                    ></b-form-radio-group>
                </b-form-group>
                <b-form-group label-cols-sm="3" label-for="ida_comollegoapuerto" label-align-sm="right" class="mb-0"
                              :label="item.mediodesplazamiento.mediocomollegar.label">
                    <b-form-radio-group
                            v-model="item.mediodesplazamiento.mediocomollegar.valor"
                            @input="onChange_autocompletar"
                            class="pt-2"
                            style="text-align:left"
                            :options="textos.comoiraestacion.options"
                    ></b-form-radio-group>
                </b-form-group>
            </div>
            <b-form-group v-if="fase != 'VUELTA'" label-align="left" label-align-md="right" label-cols-md="3" :label="textos.alojamiento.noches" class="mb-2">
                <b-form-input required
                              type="number"
                              v-model="item.alojamiento.noches"
                              @change="onChange_autocompletar"
                              class="pt-2 mb-2"
                              style="text-align:left; width:100px"
                              :options="textos.mediotransporte.options">
                </b-form-input>
                <b-form-textarea
                        v-model="item.alojamiento.observaciones"
                        class="text-align-left"
                        :placeholder="textos.alojamiento.observaciones"
                ></b-form-textarea>
            </b-form-group>
        </b-form-group>
    </b-card>
</template>

<script>
    export default {
        props: {
            fase: {
                type: String,
                default: ''
            },
            textos: Object,
            form: Object,
            item: Object
        },
        data() {
            return {
                item2: this.$parent.crearItem()
            }
        },
        name: "SolicitudViajeFase",
        mounted: function() {
            console.log(this.item );
        },
        methods: {
            eliminarparada() {
                let pn = this.fase.split("#");
                console.log("eliminarparada", pn[1]);
                this.form.paradas.splice(pn[1]-1, 1);
            },
            onChange_autocompletar() {
                this.$parent.autocompletar();
            },
            generarId(codigo) {
                return codigo+Math.random();
            }
        }
    }
</script>

<style scoped>

</style>