import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Ejemplosbootstrap from '../views/EjemplosBootstrap.vue'

Vue.use(VueRouter)
const moment = require('moment')
require('moment/locale/es')

Vue.use(require('vue-moment'), {
  moment
})
console.log(Vue.moment().locale())

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "about" */ '../views/About.vue')
    }
  },
  {
    path: '/ejemplos-bootstrap',
    name: 'ejemplosbootstrap',
    component: Ejemplosbootstrap
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
