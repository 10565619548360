<template>
    <div>
        <FormTags />
        <Select />
    </div>
</template>

<script>
    import FormTags from '@/components/ejemplos/FormTags.vue'
    import Select from '@/components/ejemplos/Select.vue'

    export default {
        name: "EjemplosBoostrap",
        components: {
            FormTags,
            Select
        }
    }
</script>

<style scoped>

</style>