<template>
    <b-card class="solicitudviajefase" bg-variant="light">
        <b-form-group label-cols-lg="3" :label="'VIAJERO(S)'" label-size="lg" label-class="font-weight-bold pt-0" class="mb-0">
            <SeleccionPersonasManusaMultiple
                    :textodescripcion="textos.viajeros.descripcion"
                    :textoagregarpersona="textos.viajeros.agregarpersona"
                    :personasseleccionadas="form.viajeros"
                    @update-ps="updateviajeros">
            </SeleccionPersonasManusaMultiple>
        </b-form-group>
    </b-card>
</template>

<script>
    import SeleccionPersonasManusaMultiple from '@/components/solicitudviaje/SeleccionPersonasManusaMultiple.vue';

    export default {
        components: {
            SeleccionPersonasManusaMultiple
        },
        props: {
            textos: Object,
            form: Object
        },
        data() {
            return {
            }
        },
        name: "SolicitudViajeViajeros",
        methods: {
            updateviajeros(upd) {
                console.log("updateviajeros", upd);
                this.form.viajeros=upd;
            }
        }
    }
</script>

<style scoped>

</style>