<template>
    <div>
        <b-form-tags v-model="value" class="mb-2"></b-form-tags>
        <p>Value: {{ value }}</p>
    </div>
</template>

<script>
    export default {
        name: "FormTags",
        data() {
            return {
                value: ['apple', 'orange']
            }
        }
    }
</script>

<style scoped>

</style>