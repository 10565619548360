<template>
    <b-form-group label-cols-sm="4" :label="textos.tipo.label" label-align-sm="right" class="mb-2">
        <b-form-radio-group required id="tipo" v-model="form.tipo" class="pt-2" @input="onChange_tipo" style="text-align:left;"
                            :options="textos.tipo.options">
        </b-form-radio-group>
    </b-form-group>
</template>

<script>
    export default {
        props: {
            textos: Object,
            form: Object
        },
        name: "SolicitudViajeTipo",
        methods: {
            onChange_tipo() {
                this.$parent.cambiartipo();
            }
        }

    }
</script>

<style scoped>

</style>