import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

Vue.config.productionTip = false

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import msal from 'vue-msal'

Vue.use(msal, {
  auth: {
    clientId: '4b6b3f10-3788-433f-bc92-1453b41fa906'
  }
});
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

new Vue({
  created() {
    // if (!this.$msal.isAuthenticated()) {
    //   this.$msal.signIn();
    // }
  },
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
