<template>
    <div class="pt-4">
        <h1>{{textos.titulo}}</h1>
        <div class="alert alert-info">{{textos.introduccion}}</div>

        <b-form @submit="onSubmit" @reset="onReset" v-if="show">
            <SolicitudViajeTipo :form="form" :textos="textos"></SolicitudViajeTipo>
            <SolicitudViajeFase :fase="'IDA'" :form="form" :item="form.ida" :textos="textos"></SolicitudViajeFase>
            <div id="paradas" v-if="form.tipo=='DMP'" class="mb-2">
                <SolicitudViajeFase v-for="(it, index) in form.paradas" :fase="'PARADA #' + (1+index)" :form="form" :item="it" :textos="textos"></SolicitudViajeFase>
                <b-button pill @click="nuevaparada">
                    <b-icon icon="plus" scale="1.5" class="border rounded-circle"></b-icon> Añadir parada
                </b-button>
            </div>
            <SolicitudViajeFase v-if="form.tipo!='IDA'" :fase="'VUELTA'" :form="form" :item="form.vuelta" :textos="textos"></SolicitudViajeFase>
            <SolicitudViajeViajeros :form="form" :textos="textos"></SolicitudViajeViajeros>
        </b-form>
        <b-card v-show="mostrarformdata" class="mt-3" header="Form Data Result">
            <pre class="m-0">{{ form }}</pre>
        </b-card>
    </div>
</template>

<script>
    import SolicitudViajeTipo from '@/components/solicitudviaje/SolicitudViajeTipo.vue';
    import SolicitudViajeFase from '@/components/solicitudviaje/SolicitudViajeFase.vue';
    import SolicitudViajeViajeros from '@/components/solicitudviaje/SolicitudViajeViajeros.vue'
    import moment from 'moment';

    export default {
        name: "SolicitudViaje",
        components: {
            SolicitudViajeTipo,
            SolicitudViajeFase,
            SolicitudViajeViajeros
        },
        data() {
            return {
                mostrarformdata: false,
                form: {
                    tipo: '',
                    ida: {},
                    vuelta: {},
                    paradas: [],
                    viajeros: ["ANGEL ORTEGA FOSCH"]
                },
                textos: {
                    titulo: "Solicitud de viaje",
                    introduccion: "Para que podamos gestionar tu viaje, debes completar el siguiente formulario:",
                    tipo: {
                        label: "Tipo de viaje:",
                        options: [
                            { text: 'Ida y vuelta', value: 'IYV', disabled: false },
                            { text: 'Destinos múltiples', value: 'DMP', disabled: false },
                            { text: 'Solo ida', value: 'IDA', disabled: false }
                        ]
                    },
                    autocompletar: "Autocompletar",
                    origen: {
                        label: "Origen:",
                        placeholder: "Indica desde donde sales",
                    },
                    destino: {
                        label: "Destino:",
                        placeholder: "Indica el destino de tu viaje"
                    },
                    mediotransporte: {
                        label: "Medio de transporte:",
                        placeholder: "Cómo te vas a desplazar de origen a destino",
                        options: [
                            { text: 'Taxi', value: 'TAX', disabled: false },
                            { text: 'Vehículo de alquiler', value: 'ALQ', disabled: false },
                            { text: 'Lanzadera', value: 'LAN', disabled: false },
                            { text: 'Vehículo propio', value: 'VEH', disabled: false },
                            { text: 'Avión', value: 'AVI', disabled: false },
                            { text: 'Tren', value: 'TRE', disabled: false },
                            { text: 'Ferry', value: 'FER', disabled: false },
                            { text: 'Me es indiferente (avión, tren, ...)', value: 'IND', disabled: false }
                        ]
                    },
                    fechasalida: {
                        label: "Cuándo:",
                        labellanzaderaida: 'Fecha y hora a la que cogerás el coche:',
                        labellanzaderavuelta: 'Fecha y hora a la que dejarás el coche:'
                    },
                    preferenciahoraria: {
                        label: "A qué hora prefieres salir:",
                        placeholderobservaciones:"Por ejemplo, he de estar en destino a las 09.00",
                        options:[
                            { text: 'Mañana', value: 'M', disabled: false },
                            { text: 'Tarde', value: 'T', disabled: false },
                            { text: 'Noche', value: 'N', disabled: false },
                            { text: 'Indiferente', value: 'I', disabled: false }
                        ]
                    },
                    preferenciaposicion: {
                        label: "Qué posición prefieres:",
                        options:[
                            { text: 'Pasillo', value: 'P', disabled: false },
                            { text: 'Ventana', value: 'V', disabled: false },
                            { text: 'Centro', value: 'C', disabled: false },
                            { text: 'Indiferente', value: 'I', disabled: false }
                        ]
                    },
                    comoiraestacion: {
                        labelaeropuerto: "Cómo irás al aeropuerto:",
                        labelestacion: "Cómo irás a la estación:",
                        labelpuerto: "Cómo irás al puerto:",
                        labellugardesalida: "Cómo irás al lugar de embarque:",
                        options: [
                            { text: 'Taxi', value: 'TAX', disabled: false },
                            { text: 'Vehículo propio', value: 'VEH', disabled: false }
                        ]
                    },
                    alojamiento: {
                        noches: "Si necesitas alojamiento, indica el número de noches y si tienes alguna observación que indicar",
                        observaciones: "p.e. Me gustaría repetir en el hotel 'El Póney Pisador'"
                    },
                    viajeros: {
                        descripcion: "Indica el nombre de todas las personas que tendrán este mismo trayecto",
                        labelnombre: "Nombre",
                        agregarpersona: "Agregar viajero"
                    }
                },
                show: true
            }
        },
        beforeMount: function() {
            this.form.tipo = "IYV";
            this.form.ida = this.crearItem();
            this.form.vuelta = this.crearItem();
            this.cambiartipo();
        },
        methods: {
            onSubmit(evt) {
                evt.preventDefault()
                alert(JSON.stringify(this.form))
            },
            onReset(evt) {
                evt.preventDefault()
                this.form.tipo = "IYV";
                this.form.ida = this.crearItem();
                this.cambiartipo();

                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            },
            crearItem() {
                return {
                    autocompletar: true,
                    mediodesplazamiento: {
                        valor: '',
                        requiereindicarmediocomollegar: false,
                        mediocomollegar: {
                            label: '',
                            valor: ''
                        },
                        preferenciahoraria: {
                            valor: 'I',
                            observaciones: ''
                        },
                        preferenciaposicion: {
                            valor: 'I',
                            observaciones: ''
                        }
                    },
                    origen:'',
                    destino:'',
                    cuando: {
                        fecha:'',
                        hora:'',
                        observaciones:''
                    },
                    alojamiento: {
                        noches: 0,
                        observaciones: ''
                    }
                };
            },
            cambiartipo() {
                if (this.form.tipo != 'DMP')
                    this.form.paradas = [];

                if (this.form.tipo == 'IDA') {
                    this.form.vuelta = null;
                } else if (this.form.tipo == 'DMP') {
                    this.form.paradas = [];
                    this.form.paradas.push(this.crearItem());
                    if (!this.form.vuelta) this.form.vuelta = this.crearItem();
                } else if (this.form.tipo == 'IYV') {
                    if (!this.form.vuelta) this.form.vuelta = this.crearItem();
                }
                this.autocompletar();
            },
            nuevaparada() {
                this.form.paradas.push(this.crearItem());
                this.autocompletar();
            },
            controlmediodesplazamiento(item) {
                console.log("mediodesplazamiento", item.mediodesplazamiento);
                if (["AVI","TRE","FER","IND"].indexOf(item.mediodesplazamiento.valor)>=0) {
                    item.mediodesplazamiento.requiereindicarmediocomollegar=true;
                    switch (item.mediodesplazamiento.valor) {
                        case "AVI": item.mediodesplazamiento.mediocomollegar.label = this.textos.comoiraestacion.labelaeropuerto; break;
                        case "TRE": item.mediodesplazamiento.mediocomollegar.label = this.textos.comoiraestacion.labelestacion; break;
                        case "FER": item.mediodesplazamiento.mediocomollegar.label = this.textos.comoiraestacion.labelpuerto; break;
                        case "IND": item.mediodesplazamiento.mediocomollegar.label = this.textos.comoiraestacion.labellugardesalida; break;
                    }
                } else
                    item.mediodesplazamiento.requiereindicarmediocomollegar=false;
            },
            autocompletar() {
                console.log("autocompletar-general")
                let destinoanterior = this.form.ida.destino;
                let mediodesplazamientoanterior = this.form.ida.mediodesplazamiento.valor;
                let siguientefechateorica = ""
                if (this.form.ida.cuando.fecha!="")
                    siguientefechateorica = moment(this.form.ida.cuando.fecha, "YYYY-MM-DD").add(this.form.ida.alojamiento.noches, "days");
                if (true) {
                    this.controlmediodesplazamiento(this.form.ida);
                    if (this.form.paradas.length) {
                        for (let i=0; i<this.form.paradas.length; i++) {
                            this.controlmediodesplazamiento(this.form.paradas[i]);
                            if (this.form.paradas[i].autocompletar) {
                                this.form.paradas[i].origen = destinoanterior;
                                if (["LAN"].indexOf(this.form.ida.mediodesplazamiento.valor)>=0)
                                    this.form.paradas[i].mediodesplazamiento.valor = "LAN";
                                this.form.paradas[i].cuando.fecha = siguientefechateorica==""?"":siguientefechateorica.format("YYYY-MM-DD");
                            }
                            destinoanterior = this.form.paradas[i].destino;
                            mediodesplazamientoanterior = this.form.paradas[i].mediodesplazamiento.valor;
                            siguientefechateorica = this.form.paradas[i].cuando.fecha;
                            if (this.form.paradas[i].cuando.fecha !="")
                                siguientefechateorica = moment(this.form.paradas[i].cuando.fecha , "YYYY-MM-DD").add(this.form.paradas[i].alojamiento.noches, "days");
                        }
                    }
                    if (this.form.vuelta) {
                        this.controlmediodesplazamiento(this.form.vuelta);
                        if (this.form.vuelta.autocompletar) {
                            this.form.vuelta.origen = destinoanterior;
                            this.form.vuelta.mediodesplazamiento.valor = this.form.ida.mediodesplazamiento.valor;
                            this.form.vuelta.destino = this.form.ida.origen;
                            this.form.vuelta.cuando.fecha = siguientefechateorica==""?"":siguientefechateorica.format("YYYY-MM-DD");
                        }
                    }
                }

            },
            autocompletar2() {
                if (this.form.vuelta_autocompletar && this.form.tipo == "IYV") {
                    this.form.vuelta_destino = this.form.ida_origen;
                    this.form.vuelta_origen = this.form.ida_destino;
                    this.form.vuelta_mediodesplazamiento = this.form.ida_mediodesplazamiento;
                    let vueltateorica = this.form.ida_fecha;
                    if (this.form.ida_fecha!="") {
                        vueltateorica = moment(this.form.ida_fecha, "YYYY-MM-DD").add(this.form.ida_noches, "days");
                        this.form.vuelta_fecha = vueltateorica.format("YYYY-MM-DD")
                    } else
                        this.form.vuelta_fecha = "";
                }

                console.log(this.form.ida_mediodesplazamiento);
                switch (this.form.ida_mediodesplazamiento) {
                    case "TRE":
                        this.form.ida_mediodesplazamiento_caracteristicas = {
                            "labelcomollegar": this.textos.comoiraestacion.labelestacion,
                            "requierecomollegar": true
                        };
                        break;
                    case "FER":
                        this.form.ida_mediodesplazamiento_caracteristicas = {
                            "labelcomollegar": this.textos.comoiraestacion.labelpuerto,
                            "requierecomollegar": true
                        };
                        break;
                    case "AVI":
                        this.form.ida_mediodesplazamiento_caracteristicas = {
                            "labelcomollegar": this.textos.comoiraestacion.labelaeropuerto,
                            "requierecomollegar": true
                        };
                        break;
                    default:
                        this.form.ida_mediodesplazamiento_caracteristicas = {
                            "labelcomollegar": "",
                            "requierecomollegar": false
                        };
                }
                console.log(this.form.ida_mediodesplazamiento_caracteristicas);

            }
        }
    }
</script>

<style scoped>
    .solicitudviajefase {
        margin-bottom:1em;
    }
</style>