<template>
  <div>
      <div v-show="mostrarcabecera">
      </div>
      <div v-if="esinicio">
          <b-img fluid alt="Vue logo" :src="require('../assets/logo.png')"></b-img>
          <h1>Solicitud de viaje</h1>
          <b-button pill variant="success" size="lg" @click="esinicio=false">Comenzar</b-button>
      </div>
      <div v-else>
          <SolicitudViaje />
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import SolicitudViaje from '@/components/solicitudviaje/SolicitudViaje.vue'

export default {
  name: 'home',
  data() {
      return {
          esinicio: true,
          mostrarcabecera: true
      }
  },
    /*mounted: function() {
        url = "https://graph.microsoft.com/v1.0/sites?search=*";

        const options = {
            authProvider,
        };

        const client = Client.init(options);

        let res = client.api('/sites/{site-id}')
            .get();
    },*/
  components: {
    SolicitudViaje
  }
}
</script>
<style scoped>
</style>